import GenericClass from "@/models/generic-class";

export default class RouteVisit extends GenericClass {
	idRoute; // number;
	startDate; // Date;
	endDate; // Date;

	constructor(idRoute, startDate, endDate) {
		super();
		this.idRoute = idRoute;
		this.startDate = startDate;
		this.endDate = endDate;
	}
}
